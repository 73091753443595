<template>
    <setting-block-layout
        :loading="loading"
        @save="onSave"
        @cancel="onCancel"
    >
        <template #body>
            <div class="profile-header-block__inner-row account-settings_checkboxes">
                <div class="profile-header-block--value">
                    <form-checkbox
                        v-model="setting.chk_email_notifications"
                        color="orange darken-2"
                        :label="'Allow e-mail notifications (transactional e-mails)'"
                        @input="updateCheckboxes($event, 'chk_email_notifications')"
                    />
                    <form-checkbox
                        v-model="setting.chk_email_marketing"
                        color="orange darken-2"
                        :label="'Agree to receive e-mails (newsletter, discount codes, promotional materials related to our services etc.)'"
                        @input="updateCheckboxes($event, 'chk_email_marketing')"
                    />
                    <!-- <form-checkbox
                        v-model="setting.chk_sms_notifications"
                        color="orange darken-2"
                        :label="'Receive sms-notifications'"
                        @input="updateCheckboxes($event, 'chk_sms_notifications')"
                    /> -->
                    <form-checkbox
                        v-model="setting.chk_calls"
                        color="orange darken-2"
                        :label="'Allow phone calls from Support Team'"
                        @input="updateCheckboxes($event, 'chk_calls')"
                    />
                    <div class="profile-messengers">
                        <template v-if="setting.chk_calls">
                            <span class="profile-messengers-title">Select time range:</span>
                            <range-date-picker
                                :label="'Time range'"
                                :from="from"
                                :to="to"
                                :flow-from="['time']"
                                type-from="time"
                                :flow-to="['time']"
                                type-to="time"
                                :have-max-date="false"
                                :format-from="null"
                                :format-to="null"
                                @onDatesSelect="updateDates"
                            />
                            <!-- radio-group -->
                            <span class="profile-messengers-title">Preferred messenger:</span>
                            <div class="profile-messengers-checkbox-group">
                                <template
                                    v-for="(messenger, i) in allowedMessengers"
                                >
                                    <form-checkbox
                                        :key="i"
                                        :label="messenger.text"
                                        color="orange darken-2"
                                        :is-checked="messengers.some((item) => item === messenger.value)"
                                        :value="messengers.some((item) => item === messenger.value)"
                                        :name="messenger.value"
                                        @input="selectMessenger($event, messenger.value)"
                                    />
                                </template>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </template>
    </setting-block-layout>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex';
import { eventBus } from '@/helpers/event-bus';

import SettingBlockLayout from '@/components/account-settings/SettingBlockLayout';
import RangeDatePicker from '@/components/common/form-elements/RangeDatePicker.vue'
import {
    ACTION_CLIENT_UPDATE_SETTINGS
} from '@/store/modules/client/action-types';

const {
    mapActions: mapClientActions
} = createNamespacedHelpers('client');

export default {
    components: {
        SettingBlockLayout,
        RangeDatePicker
    },
    metaInfo: {
        title: 'Notification settings'
    },

    data() {
        return {
            setting: {},
            from: '',
            to: '',
            messengers: [],
            loading: false
        }
    },
    computed: {
        ...mapGetters('client', [
            'getterChkNotifications',
            'getterCallParams'
        ]),
        allowedMessengers() {
            return [
                {
                    value: 'whatsapp',
                    text: 'WhatsApp'
                },
                {
                    value: 'facebook',
                    text: 'Facebook Messenger'
                },
                {
                    value: 'telegram',
                    text: 'Telegram'
                },
                {
                    value: 'viber',
                    text: 'Viber'
                }
            ]
        }
    },
    created() {
        this.setting = { ...this.getterChkNotifications }
        const { from, to, messengers } = this.getterCallParams
        this.from = from
        this.to = to
        this.messengers = messengers
    },
    methods: {
        ...mapClientActions({
            updateSettings: ACTION_CLIENT_UPDATE_SETTINGS
        }),
        onCancel() {
            this.$router.push({ name: 'profile' })
        },
        async onSave() {
            try {
                this.loading = true
                await this.updateSettings({
                    ...this.setting,
                    contact: {
                        messenger: this.messengers,
                        from: this.from,
                        to: this.to
                    }
                })
                this.$router.push({ name: 'profile' })
                eventBus.$emit('showSnackBar', 'Your notification settings have been saved', 'success');
            } catch (err) {
                eventBus.$emit('showSnackBar', 'Failed to update settings', 'error');
            } finally {
                this.loading = false
            }
        },
        updateCheckboxes(value, key) {
            this.setting[key] = Number(value)
        },
        updateDates({ from, to }) {
            this.from = from
            this.to = to
        },
        selectMessenger(e, type) {
            const index = this.messengers.findIndex((messanger) => messanger === type)
            if (index >= 0) {
                this.messengers.splice(index, 1)
                return
            }
            this.messengers.push(type)
        }
    }
}
</script>

<style lang="scss">
.account-settings_checkboxes {
    .checkox-block {
        margin-bottom: 30px;
        &:last-child {
            margin-bottom: 0;
        }
        &:first-child {
            margin-top: 20px;
        }
    }
}
.profile-messengers {
    max-width: 480px;
    margin: 0 auto;
    font-weight: initial;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    &-title {
        margin: 16px 0;
        font-weight: 500;
    }
    &-checkbox {
        &-group {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
            .checkox-block {
                margin: 0 !important;
            }
        }
    }
    .form-datepicker__fieldset {
        background: #fff;
    }
}
</style>
